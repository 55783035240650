import React from 'react'
import { Parallax } from 'react-parallax'
import { CFImage, CFView, DefaultScreen, MobileScreen } from 'components'
import { about, mobileAbout } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center>
          <CFImage
            src={mobileAbout}
            w="100%"
            alt="Hee Rae Deung About"
            mt="-1px"
          />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView m="0 auto">
          <CFView column center w="100%" maxWidth="1400px">
            <CFImage src={about} w="100%" alt="about" />
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
